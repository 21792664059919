<template>
  <div>
    <div class="main" v-if="loaded">
      <v-expansion-panels>
        <v-expansion-panel v-for="(activity, ua_i) in userActivity" :key="'ua_'+ua_i"
          @click.native="activityData[activity._id] == undefined ? fetchUserActivityData(activity) : false"
        > 
          <v-expansion-panel-header>Activity {{ ua_i + 1}}: {{ $languageProcessor.getEnText(activity.activityData.name) }}</v-expansion-panel-header>
          <v-expansion-panel-content>          
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>

            <!-- Activity Info -->

            <table class="table table-bordered" v-if="activity && debug">
              <thead class="thead-light">
                <tr>
                  <th style="width:50%" scope="col">Title</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(activityValue, activityKey, uao_i) in activity" :key="'uao_'+uao_i">
                  <td v-if="debugActivityInfo.includes(activityKey)" class="bold">
                    {{ activityKey }}
                  </td>
                  <td v-if="debugActivityInfo.includes(activityKey)">
                    {{ activityValue }}
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Activity Data -->
            <div v-if="!activityDataReady">
              <center>Fetching data...</center>
            </div>
            <div v-else-if="activityDataReady && activityData[activity._id] && activityData[activity._id].length > 0">
              <table v-for="(activityDataArray, a_i) in activityData[activity._id]" class="table table-bordered" :key="'ua_'+ua_i+'a_'+a_i">
            
                <thead class="thead-dark">
                  <tr>
                    <th style="width:30%" scope="col">Title</th>
                    <th style="width:50%" scope="col">Answer</th>
                    <th style="width:20%" scope="col">Question Type</th>
                  </tr>
                </thead>
                <tbody v-for="(data, ad_i) in activityDataArray.data" :key="'ua_'+ua_i+'a_'+a_i+'ad_'+ad_i">
                  <tr v-if="data != undefined && data != null">
                    <td>{{ data.title }}</td>
                    <td>
                      <v-textarea v-if="!['camera', 'cameraRecognition', 'imageUrlQuestion', 'location'].includes(data.questionType)"
                        :value="activityData[activity._id][a_i].data[ad_i]['answer']"
                        @change.native="activityData[activity._id][a_i].data[ad_i]['answer'] = $event.target.value"
                        solo
                        auto-grow
                        hide-details
                        rows="1"
                      />
                      <v-img v-else-if="(data.questionType == 'camera' || data.questionType == 'cameraRecognition') && data.answer"
                        class="white--text"
                        height="100%"
                        width="500px"
                        :src="`data:image/jpeg;base64,${data.answer}`"
                      />
                      <v-img v-else-if="data.questionType == 'imageUrlQuestion' && data.answer" class="white--text" height="100%" width="500px" :src="data.answer" />
                      <Map v-else-if="data.questionType == 'location' && data.answer" :coordinates="data.answer" :zoom="12" />
                      <div v-else >{{ data.answer }}</div>
                    </td>
                    <td>{{ data.questionType }}</td>
                  </tr>
                </tbody>
              </table>
            </div> 
            <div v-else>
                <center>No Data is available at the moment.</center>
            </div> 
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <OOHDiaog
      ref="OOHDialog"
      :modalUAData="modalUAData"
      :oohConfig="oohConfig"
    />
  </div>
</template>


<script>
import RestResource from "../services/dataServiceVD";
import Map from "../components/Map.vue"
import OOHDiaog from "../pagesMobile/UserActivity/oohDialog.vue"

const service = new RestResource();
const Hashids = require("hashids/cjs");

export default {
  components: {
    Map, OOHDiaog
  },
  data() {
    return {
      visibleActivityInfo: ['activityType', 'expiry', 'start', 'status', 'recurring'],
      debugActivityInfo: ['userActivityId', 'projectId', 'activityId', 'userId'],
      debug: this.$route.query.debug == "true" ? true : false,
      loaded: false,
      userActivity: [],
      activityData: {},
      activityDataReady: true,
      
      oohConfig: {
        showDialog: false
      },
      modalUAData: null,
      modalData: [],
    };
  },
  mounted() {
    if (this.$route.params) {
      let paramsToSend = {}

      if (this.$route.params.userId && this.$route.params.projectId) {
        paramsToSend.userId = this.$route.params.userId
        paramsToSend.projectId = this.$route.params.projectId
      }

      if (this.$route.query.activityId) {
        paramsToSend.activityId = this.$route.query.activityId
      }

      if (this.$route.params.hashTimestamp) {
        const hashids = new Hashids("IZC", 32);
        let decodedTimestamp = hashids.decode(this.$route.params.hashTimestamp);

        if (decodedTimestamp.length == 0 || decodedTimestamp[0] == undefined || decodedTimestamp[0] < Date.now()) {
          this.redirectToNotFoundPage();
        }
      }

      if (Object.keys(paramsToSend).length == 0) {
        this.redirectToNotFoundPage();  
      }

      this.fetchUserProject(paramsToSend)
    } else {
      this.redirectToNotFoundPage();
    }
  },
  methods: {
    fetchUserProject(ids){
      this.$setLoader();

      let data = { ignoreStatus: true, doNotPaginate: true, doNotProject: true }

      service.viewUserActivities({ ...data, ...ids }).then(r => {
        if (this.debug) {
          // eslint-disable-next-line
          console.log(r.data)
        }

        r.data.forEach(activity => {
          activity.recurring = activity.projectData.activities.recurring
          activity.activityType = activity.activityData.type
          activity.userActivityId = activity._id
        })

        this.userActivity = r.data;
        this.loaded = true
        this.$disableLoader();
      }).catch(e => {
        this.$disableLoader();
        alert(e.message)
      })
    },

    modalFindAndReplace(activityData, status, type) {
      this.oohConfig.showDialog = status
      this.oohConfig.dialogType = type

      if (status == true) {
        this.modalUAData = activityData
      } else if (status == false) {
        this.modalUAData = null
        this.$refs.OOHDialog.modalData = []
        this.$refs.OOHDialog.searchText = null
      }
    },

    fetchUserActivityData(activity) {
      if (activity.activityId == "5d351ed836dc1e3a803ffef8") {
        activity.showOOHModal = true
      }

      this.activityDataReady = false

      let data = { userActivityId : activity._id, valid: this.$route.query.valid ? this.$route.query.valid : true }

      if (this.$route.query.startDate) {
        data.startDate = this.$route.query.startDate
      }

      if (this.$route.query.endDate) {
        data.endDate = this.$route.query.endDate
      }

      if (this.$route.query.userActivityDataId) {
        data.userActivityDataId = this.$route.query.userActivityDataId
      }

      service.fetchUserActivityDataCustom(data).then(r => {      
        this.activityData[activity._id] = r.data;        
        this.$nextTick (() => {
          this.activityDataReady = true;
        });
      })
    },
    redirectToNotFoundPage() {
      this.$router.push({ path: "/404" });
      this.$disableLoader();
    },
  }
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap.scss";

table {
  table-layout: fixed; 
  width: 100%
}

td {
  word-wrap: break-word;
}

#activity {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
}

#activity td,
#activity th {
  border: 1px solid #ddd;
  padding: 8px;
}

#activity tr:nth-child(even) {
  background-color: #f2f2f2;
}

#activity tr:hover {
  background-color: #ddd;
}

#activity th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #2ca8a0;
  color: white;
  font-size: 18px;
  max-width: 10px !important;
}

#activity td {
  font-size: 16px;
}

.btnPosition2 {
  margin-top: 118px;
  margin-left: -85px;

  flex-direction: column;
}

.main {
  display: -webkit-flex; /* Safari */
  -webkit-flex-direction: row-reverse; /* Safari 6.1+ */
  display: flex;
  flex-direction: column;
}
</style>